const UNLIMITED_MAX_CAPACITY_HARD_LIMIT = 5000;

export default {
  subscription_basic_200_annual: {
    maxCapacity: 200,
    tier: 'basic',
    billingPeriod: 'annual',
  },
  subscription_basic_200_monthly: {
    maxCapacity: 200,
    tier: 'basic',
    billingPeriod: 'monthly',
  },
  subscription_basic_500_annual: {
    maxCapacity: 500,
    tier: 'basic',
    billingPeriod: 'annual',
  },
  subscription_basic_500_monthly: {
    maxCapacity: 500,
    tier: 'basic',
    billingPeriod: 'monthly',
  },
  subscription_basic_unlimited_annual: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'basic',
    billingPeriod: 'annual',
  },
  subscription_basic_unlimited_monthly: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'basic',
    billingPeriod: 'monthly',
  },

  subscription_professional_200_annual: {
    maxCapacity: 200,
    tier: 'professional',
    billingPeriod: 'annual',
  },
  subscription_professional_200_monthly: {
    maxCapacity: 200,
    tier: 'professional',
    billingPeriod: 'monthly',
  },
  subscription_professional_500_annual: {
    maxCapacity: 500,
    tier: 'professional',
    billingPeriod: 'annual',
  },
  subscription_professional_500_monthly: {
    maxCapacity: 500,
    tier: 'professional',
    billingPeriod: 'monthly',
  },
  subscription_professional_unlimited_annual: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'professional',
    billingPeriod: 'annual',
  },
  subscription_professional_unlimited_monthly: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'professional',
    billingPeriod: 'monthly',
  },

  subscription_enterprise_200_annual: {
    maxCapacity: 200,
    tier: 'enterprise',
    billingPeriod: 'annual',
  },
  subscription_enterprise_200_monthly: {
    maxCapacity: 200,
    tier: 'enterprise',
    billingPeriod: 'monthly',
  },
  subscription_enterprise_500_annual: {
    maxCapacity: 500,
    tier: 'enterprise',
    billingPeriod: 'annual',
  },
  subscription_enterprise_500_monthly: {
    maxCapacity: 500,
    tier: 'enterprise',
    billingPeriod: 'monthly',
  },
  subscription_enterprise_unlimited_annual: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'enterprise',
    billingPeriod: 'annual',
  },
  subscription_enterprise_unlimited_monthly: {
    maxCapacity: UNLIMITED_MAX_CAPACITY_HARD_LIMIT,
    tier: 'enterprise',
    billingPeriod: 'monthly',
  },
};
