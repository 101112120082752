
export const useProductsStore = defineStore('products', {
  state: () => {
    return {
      prices: null,
      subscriptionConfigs: subscriptionConfigs,
    };
  },

  actions: {
    async fetchPrices() {
      const response = await useApi.get('/public/products/prices').catch(() => {
        useToast.error(useT('abc242'));
      });

      this.prices = response;

      return response;
    },

    async createCheckout(body) {
      const response = await useApi.post('/products/checkout', {
        body,
      }).catch(() => {
        useToast.error(useT('abc267'));
      });

      return response;
    },

    async getCheckout(params) {
      const response = await useApi.get('/public/products/checkout', {
        params,
      }).catch(() => {
        useToast.error(useT('xyz78'));
      });

      return response;
    },
  },

  getters: {
    getProductConfig(state) {
      return (product) => {
        if (product?.category === 'planSubscription') {
          return state.subscriptionConfigs[product?.key];
        }

        if (product?.category === 'planSingleUse') {
          return {
            tier: product?.data?.tier,
            maxCapacity: product?.data?.maxCapacity,
          };
        }

        if (product?.category === 'addon') {
          return {
            name: product?.data?.name,
            billingPeriod: 'monthly', // NOTE: create a addons configs file is there will be more then 3 addons
          };
        }

        return false;
      };
    },
    getProductPrice(state) {
      return (key) => state.prices?.[useWorkspaceStore().getCurrency]?.[key];
    },
  },
});
